export const services = [
  // {
  //   title: "Free Shipping",
  //   tagline: "From all orders over $5",
  //   image: "images/service.png",
  // },
  {
    title: "Daily Surprise Offers",
    tagline: "Save upto 25% off",
    image: "images/service-02.png",
  },
  {
    title: "Support 24/7",
    tagline: "Shop with an expert",
    image: "images/service-03.png",
  },
  {
    title: "Affordable Prices",
    tagline: "Get Factory Default Price",
    image: "images/service-04.png",
  },
  // {
  //   title: "Secure Payments",
  //   tagline: "100% Protected Payment",
  //   image: "images/service-05.png",
  // },
];

export const products = 
[
  {
    _id: "1",
    title: "Motorola Moto G Stylus 5G",
    brandName: "Motorola",
    oldPrice: "$189.99",
    newPrice: "$149.99",
    category: "Electronics",
    subCategory: "Mobile Phones",
    link: "https://amzn.to/3sc9izS",
    reviewValue: "4.3",
    reviewOutOf: "5",
    revewCount: "888",
    productImage1: "https://m.media-amazon.com/images/I/51fW4SCfaPL._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/51hT7btpukL._AC_SX679_.jpg",
    description: "Motorola Moto G Stylus 5G | 2021 | 2-Day Battery | Unlocked | Made for US 4/128GB | 48MP Camera | Cosmic Emerald",
    size: [],
  },
  {
    _id: "2",
    title: "SAMSUNG Galaxy A14",
    brandName: "Samsung",
    oldPrice: "$146.90",
    newPrice: "$140.00",
    category: "Electronics",
    subCategory: "Mobile Phones",
    link: "https://amzn.to/3EdDc9L",
    reviewValue: "4.2",
    reviewOutOf: "5",
    revewCount: "118",
    productImage1: "https://m.media-amazon.com/images/I/61P6E8GGhvL._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/61GmClFzHaL._AC_SX679_.jpg",
    description: "SAMSUNG Galaxy A14 4G LTE (128GB + 4GB) Unlocked Worldwide (Only T-Mobile/Mint/Metro USA Market) 6.6\" 50MP Triple Camera + (15W Wall Charger) (Black (SM-A145M/DS))",
    size: [],
  },
  {
    _id: "3",
    title: "Samsung Galaxy S20",
    brandName: "Samsung",
    oldPrice: "$549.97",
    newPrice: "$193.00",
    category: "Electronics",
    subCategory: "Mobile Phones",
    link: "https://amzn.to/459tFw4",
    reviewValue: "4",
    reviewOutOf: "5",
    revewCount: "715",
    productImage1: "https://m.media-amazon.com/images/I/61up7u4v6mL._AC_SY879_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/61DPd7tKdeL._AC_SY879_.jpg",
    description: "Samsung Galaxy S20 FE 5G 128GB Blue VZ",
    size: [],
  },
  {
    _id: "4",
    title: "Google Pixel 7",
    brandName: "Google",
    oldPrice: "$599.00",
    newPrice: "$499.00",
    category: "Electronics",
    subCategory: "Headphones",
    link: "https://www.google.com",
    reviewValue: "5",
    reviewOutOf: "5",
    revewCount: "2734",
    productImage1: "https://m.media-amazon.com/images/I/71loUpMg0pL._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/71loUpMg0pL._AC_SX679_.jpg",
    description: "Google Pixel 7-5G Android Phone - Unlocked Smartphone with Wide Angle Lens and 24-Hour Battery - 128GB - Obsidian",
    size: ["S", "M", "XL", "XXL"],
  },
  {
    _id: "5",
    title: "Bose QuietComfort 45",
    brandName: "Bose",
    oldPrice: "$329.00",
    newPrice: "$279.00",
    category: "Electronics",
    subCategory: "Headphones",
    link: "https://amzn.to/3OzTyy1",
    reviewValue: "5",
    reviewOutOf: "5",
    revewCount: "17771",
    productImage1: "https://m.media-amazon.com/images/I/51+AZ4oGvqL._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/51CHPyFpnoL._AC_SX679_.jpg",
    description: "Bose QuietComfort 45 Bluetooth Wireless Noise Cancelling Headphones - White Smoke",
    size: [],
  },
  {
    _id: "6",
    title: "Beats Studio3",
    brandName: "Beats",
    oldPrice: "$349.95",
    newPrice: "$231.99",
    category: "Electronics",
    subCategory: "Headphones",
    link: "https://amzn.to/47rZYYB",
    reviewValue: "5",
    reviewOutOf: "5",
    revewCount: "39008",
    productImage1: "https://m.media-amazon.com/images/I/61Xvn9KUgYL._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/61q-cI9PTML._AC_SX679_.jpg",
    description: "Beats Studio3 Wireless Noise Cancelling Over-Ear Headphones - Apple W1 Headphone Chip, Class 1 Bluetooth, 22 Hours of Listening Time, Built-in Microphone - Defiant Black-Red (Latest Model)",
    size: [],
  },
  {
    _id: "7",
    title: "Focal Stellia Headphones",
    brandName: "Focal",
    oldPrice: "$2999.00",
    newPrice: "$2399.00",
    category: "Electronics",
    subCategory: "Headphones",
    link: "https://amzn.to/3QGkqPx",
    reviewValue: "5",
    reviewOutOf: "5",
    revewCount: "40",
    productImage1: "https://m.media-amazon.com/images/I/61K2FOX55RL._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/71gSF2AfK5L._AC_SX679_.jpg",
    description: "Focal Stellia Closed-Back Circum-Aural Over-Ear Headphones (Cognac)",
    size: [],
  },
  {
    _id: "8",
    title: "Apple iPhone 14",
    brandName: "Apple",
    oldPrice: "$799.99",
    newPrice: "$646.15",
    category: "Electronics",
    subCategory: "Mobile Phones",
    link: "https://amzn.to/3s2YFPA",
    reviewValue: "5",
    reviewOutOf: "5",
    revewCount: "608",
    productImage1: "https://m.media-amazon.com/images/I/61bK6PMOC3L._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/51AP0mpTnXL._AC_SX679_.jpg",
    description: "Apple iPhone 14, 128GB, Blue - Unlocked (Renewed)",
    size: [],
  },
  {
    _id: "9",
    title: "Sony MDR-Z7M2",
    brandName: "Sony",
    oldPrice: "$899.99",
    newPrice: "$598.00",
    category: "Electronics",
    subCategory: "Headphones",
    link: "https://amzn.to/47yIdqP",
    reviewValue: "5",
    reviewOutOf: "5",
    revewCount: "100",
    productImage1: "https://m.media-amazon.com/images/I/51-z7U-8N+L._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/51jivNPE6TL._AC_SX679_.jpg",
    description: "Sony MDR-Z7M2 Hi-Res Stereo Overhead Headphones Headphone (MDRZ7M2) Black",
    size: [],
  },
  {
    _id: "10",
    title: "HIFIMAN Deva-Pro",
    brandName: "Hifiman",
    oldPrice: "$329.00",
    newPrice: "$269.00",
    category: "Electronics",
    subCategory: "Headphones",
    link: "https://amzn.to/3sbDUBD",
    reviewValue: "4",
    reviewOutOf: "5",
    revewCount: "73",
    productImage1: "https://m.media-amazon.com/images/I/71pBPjY6QkL._AC_SX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/71l9rAEIuCL._AC_SX679_.jpg",
    description: "HIFIMAN Deva-Pro Over-Ear Full-Size Open-Back Planar Magnetic Headphone with Bluetooth Dongle/Receiver, Himalaya R2R Architecture DAC, Easily Switch Between Wired and Wireless, Bluetooth 5.0",
    size: [],
  },
  {
    _id: "11",
    title: "WLIVE Nightstand, 2 Drawer Dresser for Bedroom",
    brandName: "Wlive",
    oldPrice: "$49.99",
    newPrice: "$29.99",
    category: "Furniture",
    subCategory: "Night Stand",
    link: "https://amzn.to/3OzZ2ZL",
    reviewValue: "4.4",
    reviewOutOf: "5",
    revewCount: "13090",
    productImage1: "https://m.media-amazon.com/images/I/81RcBZHXZ1L._AC_SX522_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/81CPjp5tDoL._AC_SX522_.jpg",
    description: "WLIVE Nightstand, 2 Drawer Dresser for Bedroom, Small Dresser with 2 Drawers, Bedside Furniture, Night Stand, End Table with Fabric Bins for Bedroom, Closet, Entryway, Nursery, College Dorm, Dark Grey",
    size: [],
  },
  {
    _id: "12",
    title: "Buttery Soft Leggings for Women",
    brandName: "FULLSOFT",
    oldPrice: "$13.99",
    newPrice: "$8.78",
    category: "Fashion",
    subCategory: "Women Clothing",
    link: "https://amzn.to/3QMkbCr",
    reviewValue: "4.4",
    reviewOutOf: "5",
    revewCount: "8967",
    productImage1: "https://m.media-amazon.com/images/I/61uOq5AWCqL._AC_SY879_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/61OU8A88sYL._AC_SX679_.jpg",
    description: "Buttery Soft Leggings for Women - High Waisted Tummy Control No See Through Workout Yoga Pants",
    size: ["S", "M", "L", "XL"],
  },
  {
    _id: "13",
    title: "Sleeveless Spaghetti Strap",
    brandName: "xxxiticat",
    oldPrice: "$34.78",
    newPrice: "$24.99",
    category: "Fashion",
    subCategory: "Women Clothing",
    link: "https://amzn.to/3slEHjq",
    reviewValue: "4",
    reviewOutOf: "5",
    revewCount: "16594",
    productImage1: "https://m.media-amazon.com/images/I/51nc-oMkgDL._AC_UY879_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/51UW+2S+NcL._AC_UY879_.jpg",
    description: "xxxiticat Women's Sleeveless Spaghetti Strap Satin Dress Cocktail Beach Evening Party Cowl Neck Dot Leopard Midi Dresses",
    size: ["S", "M", "L", "XL"],
  },
  {
    _id: "14",
    title: "Inmarces Workout Sets for Women 5 PCS Yoga Outfits Activewear Tracksuit Sets",
    brandName: "Inmarces",
    oldPrice: "$37.99",
    newPrice: "$33.19",
    category: "Fashion",
    subCategory: "Women Clothing",
    link: "https://amzn.to/3sr4oyM",
    reviewValue: "4",
    reviewOutOf: "5",
    revewCount: "1119",
    productImage1: "https://m.media-amazon.com/images/I/61Fl0WdadGS._AC_UX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/81duyR0xW1S._AC_UX679_.jpg",
    description: "Inmarces Workout Sets for Women 5 PCS Yoga Outfits Activewear Tracksuit Sets",
    size: ["S", "M", "L", "XL"],
  },
  {
    _id: "15",
    title: "FashionPuzzle Triple Zip Small Crossbody Bag",
    brandName: "FashionPuzzle",
    oldPrice: "$18.90",
    newPrice: "$24.99",
    category: "Fashion",
    subCategory: "Women HandBag",
    link: "https://amzn.to/3se9UEV",
    reviewValue: "5",
    reviewOutOf: "5",
    revewCount: "28655",
    productImage1: "https://m.media-amazon.com/images/I/614LZ9MO80L._AC_UX679_.jpg",
    productImage2: "https://m.media-amazon.com/images/I/61YwTN95UWL._AC_UX679_.jpg",
    description: "FashionPuzzle Triple Zip Small Crossbody Bag",
    size: [],
  }
]