import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";

import { products } from "../utils/Data";
import Container from "../components/Container";
import wish from "../images/wish.svg";
import watch from "../images/watch.jpg";
import watch2 from "../images/watch-1.avif";
import addcart from "../images/add-cart.svg";
import ReactStars from "react-rating-stars-component";
import prodcompare from "../images/prodcompare.svg";
import view from "../images/view.svg";
import Pagination from '../components/Pagination';

const CategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(12);

  const routeParams = useParams();
  const category = routeParams.category;
  const flag = routeParams.flag;

  let filteredProducts = products.filter((product) => {
    if (flag === "true") {
      return product.category === category;
    } else {
      console.log(product);
      return product.subCategory === category;
    }
  });  

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(filteredProducts.length / recordsPerPage)
  console.log(filteredProducts);

  return (
    <>
    <Container class1="featured-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">{category} Deals</h3>
          </div>
          {filteredProducts?.map((product, j) => {
                  return (<><div className="gr-3 col-3">
                  <Link
                    to={`/product/${product._id}`}
                    // to={`${
                    //   location.pathname == "/"
                    //     ? `/product/${product._id}`
                    //     : location.pathname == "/product/:id"
                    //     ? `/product/${product._id}`
                    //     : "/"
                    // }`}
                    state={{product: product}}
                    className="product-card position-relative"
                  >
                    <div className="wishlist-icon position-absolute">
                      <button className="border-0 bg-transparent">
                        <img src={wish} alt="wishlist" />
                      </button>
                    </div>
                    <br/>
                    <div className="product-image">
                      <img style={{ width: 400, height: 270 }} src={product.productImage1} className="img-fluid" alt="product image" />
                      <img style={{ width: 400, height: 270 }} src={product.productImage2} className="img-fluid" alt="product image" />
                    </div>
                    <div className="product-details">
                      <h6 className="brand">{product.brandName}</h6>
                      <h5 className="product-title">
                        {product.title}
                      </h5>
                      <ReactStars
                        count={Number(product.reviewOutOf)}
                        size={24}
                        value={Number(product.reviewValue)}
                        edit={false}
                        activeColor="#ffd700"
                      />
                      <p className="description d-none">
                        {product.description}
                      </p>
                      <p className="price">
                        <span className="red-p">{product.newPrice}</span> &nbsp; <strike>{product.oldPrice}</strike>
                      </p>
                    </div>
                    {/* <div className="action-bar position-absolute">
                      <div className="d-flex flex-column gap-15">
                        <button className="border-0 bg-transparent">
                          <img src={prodcompare} alt="compare" />
                        </button>
                        <button className="border-0 bg-transparent">
                          <img src={view} alt="view" />
                        </button>
                        <button className="border-0 bg-transparent">
                          <img src={addcart} alt="addcart" />
                        </button>
                      </div>
                    </div> */}
                  </Link>
                </div>
                </>
                )
                // if (product) {
                //   return (
                //     <ProductCard key={j} product={product} grid={4}/>
                //   );
                // }
            })
          }
          <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
          />
          {/* <ProductCard a={"foo"} b={"buzz"} grid={4}/>
          <ProductCard />
          <ProductCard />
          <ProductCard /> */}
        </div>
      </Container>
    </>
  );
}

export default CategoryPage;